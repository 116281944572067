<template>
    <div>
		<b-modal ref="modalImportHorseDescendants" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.import_descendants") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="horses" class="col-form-label">{{ $t("global.year") }} *</label>
					<e-select
						v-model="selected_year"
						id="year"
						label="year"
						:placeholder="labelTitleFormat"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="years"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.year }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.importer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Horse from '@/mixins/Horse.js'

export default {
	name: "ModalImportHorseDescendants",
	mixins: [Horse],
	data () {
		return {
			labelTitleFormat: this.getTrad("horse.choose_year"),
			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),
			
			processing: false,
			horse_id: null,
			horse: null,
			years: [],
			selected_year: null
		}
	},
	methods: {
		async init_component() {
			this.years = []
			this.processing = false
			this.horse = await this.getHorseById(this.horse_id)
			const current_year = new Date().getFullYear()

			let horse_birth_year = 1980
			if(this.horse.horse_age) {
				horse_birth_year = current_year - this.horse.horse_age
			}
			
			for(let i = horse_birth_year; i <= current_year; i++) {
				this.years.push({year: i})
			}
		},
		openModal(horse_id) {
			this.horse_id = horse_id
			this.$refs.modalImportHorseDescendants.show()
		},
		closeModal() {
			this.$refs.modalImportHorseDescendants.hide()
		},
		async checkForm() {
			if(this.selected_year) {
				if(this.horse.horse_sire) {
					this.processing = true
					await this.importDescendants(this.horse.horse_sire, this.selected_year.year)
					.then((res) => {
						if(res.retour > 0) {
							this.infoToast('toast.horses_left', {nb_horses: res.retour})
						}
						this.processing = false
						this.$emit('submit')
						this.closeModal()
					})
				}
				else {
					this.failureToast('toast.horse_no_sire')
				}
			}
			else {
				this.failureToast('toast.all_inputs')
			}
		}
	}
}
</script>